import React, { useState } from 'react';
import { ExperienceCard } from './ExperienceCard';
import color from '../themes/colors';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

export const Experience = () => {
  const [isHovered, setIsHovered] = useState(false);

  const styles = {
    boxContainer: {
      padding: '2rem 0'
    },
    cvLink: {
      textDecoration: 'none',
      color: isHovered ? color.colors.accent : color.colors.title,
      textAlign: 'start',
      padding: '0 1rem',
      display: 'flex',
      alignItems: 'center',
      transition: 'color 0.3s ease-in-out',
      fontSize: '16px'
    },
    icon: {
      marginLeft: '5px',
      transition: 'transform 0.1s ease-in-out, margin 0.1s ease-in-out',
      width: '1rem'
    },
    iconHovered: {
      transform: 'translateY(-3px) translateX(3px)',
      margin: '0 3px'
    },

  };

  const jobs = [
    {
      fecha: 'Jul.2023 - Sep.2023',
      puesto: 'Tl Backend',
      empresa: 'Certified Tech Developer',
      descripcion: 'Contribuí activamente al diseño y desarrollo del backend, implementando funcionalidades en Java, Springboot, lo que resultó en una api robusta y segura. Colaboré en el desarrollo del frontend, utilizando React.js para crear componentes reutilizables y eficientes, mejorando la interactividad del usuario y la velocidad de carga de la página. Diseñé y programé pipelines para la integración continua en GitLab, optimizando el proceso de desarrollo y asegurando lanzamientos más ágiles y eficientes.permitiendo un flujo de trabajo más fluido y consistente.',
      technologies: ['React', 'html', 'css', 'javaScript', 'Spring Boot', 'java', 'MySQL', 'Git', 'Docker', 'AWS'],
      link: 'https://www.digitalhouse.com/ar/productos/programacion/certified-tech-developer'
    },
    {
      fecha: 'Ago.2022 - Ago.2023',
      puesto: 'Desarrollador full stack',
      empresa: 'HT Financial & Insurance Service',
      descripcion: 'Contribuí proactivamente en el desarrollo y mantenimiento del software asegurando la entrega continua de actualizaciones y mejoras para mejorar la funcionalidad y la seguridad. Colaboré en la implementación de mejores prácticas de desarrollo, contribuyendo a un código más limpio, eficiente y mantenible',
      technologies: ['html', 'css', 'javaScript', 'MySQL', 'php'],
      link: 'https://htfinancial.com/'
    },
    {
      fecha: 'Feb.2022 - Jul.2022',
      puesto: 'Desarrollador full stack',
      empresa: 'VADAVO | Hosting y Telecomunicaciones',
      descripcion: 'Desarrollo front-end, con HTML5, CSS, y JavaScript. Experiencia en el desarrollo de sitios web utilizando WordPress. Experiencia en la implementación de hojas de estilos en cascada (CSS) para lograr diseños atractivos y funcionales',
      technologies: ['html', 'css', 'javaScript', 'MySQL', 'php', 'WordPress'],
      link: 'https://www.vadavo.com/'
    },
  ];
  if (window.innerWidth <= 992) {
    styles.boxContainer.padding = '0';
  }
  return (
    <div style={styles.boxContainer}>
      {jobs.map((job, index) => (
        <ExperienceCard
          key={index}
          fecha={job.fecha}
          puesto={job.puesto}
          empresa={job.empresa}
          descripcion={job.descripcion}
          technologies={job.technologies}
          link={job.link}
        />
      ))}
      <a
        href="https://drive.google.com/file/d/1Orj3l5FWZkAFn_1hs8HoDZma5LIMvdoT/view?usp=sharing"
        target="_blank"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{ textDecoration: 'none' }}
      >
        <h3 style={styles.cvLink}>
          Ver mi currículum
          <ArrowOutwardIcon sx={{ ...styles.icon, ...(isHovered && styles.iconHovered) }} />
        </h3>
      </a>
    </div>
  );
};
