import React from 'react'
import color from '../themes/colors'
import { Box, Container, Typography } from '@mui/material'
import { NavBar } from './NavBar'
import { SocialWeb } from './SocialWeb'

export const Info = () => {

    const styles = {
        boxContainer: {
            padding: '0 2rem'

        },
        title: {
            fontSize: '48px',
            lineHeight: '48px',
            textAlign: 'start',
            letterSpacing: '-1.2px',
            color: color.colors.title,
            fontWeight: '700',
            margin: '16px',
        },
        subtitle: {
            fontSize: '20px',
            lineHeight: '28px',
            textAlign: 'start',
            letterSpacing: '-0.5px',
            color: color.colors.title,
            margin: '16px'
        },
        text: {
            lineHeight: '24px',
            textAlign: 'start',
            color: color.colors.secondaryText,
            margin: '16px'
        },
    }

    return (
        <Container>
            <Box className='boxInfo' sx={styles.boxContainer}>
                <Box>
                    <Typography className='name' sx={styles.title} variant="h1">Leonardo Barrera</Typography>
                </Box>

                <Box>
                    <Typography sx={styles.subtitle} variant="subtitle1">Full Stack Developer</Typography>
                </Box>

                <Box>
                    <Typography sx={styles.text} variant="body1">Bienvenido a mi portfolio digital. ¡Explora mi trabajo como Full Stack Developer y descubre soluciones tecnológicas innovadoras! Desde aplicaciones web hasta sistemas de gestión, ¡te invito a explorar y encontrar inspiración en mis proyectos!</Typography>
                </Box>

                <NavBar />

                <SocialWeb className='social'/>

            </Box>

        </Container>
    )
}
