import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import color from '../themes/colors';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export const SocialWeb = () => {
    const [hoveredIcon, setHoveredIcon] = useState(null);

    const handleMouseEnter = (iconName) => {
        setHoveredIcon(iconName);
    };

    const handleMouseLeave = () => {
        setHoveredIcon(null);
    };

    const styles = {
        stack: {
            padding: '.5rem',
            listStyle: 'none',
            textDecoration: 'none',
        },
        icon: {
            fontSize: '24px',
            padding: '.5rem',
            transition: 'color 0.3s ease-in-out'
        },
    };

    return (
        <Stack sx={styles.stack} direction="row" spacing={1}>
            <a
                style={{
                    ...styles.icon,
                    color: hoveredIcon === 'github' ? color.colors.title : color.colors.secondaryText
                }}
                href="https://github.com/barreraleonardo"
                target='_blank'
                onMouseEnter={() => handleMouseEnter('github')}
                onMouseLeave={handleMouseLeave}
            >
                <GitHubIcon />
            </a>
            <a
                style={{
                    ...styles.icon,
                    color: hoveredIcon === 'linkedin' ? color.colors.title : color.colors.secondaryText
                }}
                href="https://www.linkedin.com/in/leonardo-barrera/"
                target='_blank'
                onMouseEnter={() => handleMouseEnter('linkedin')}
                onMouseLeave={handleMouseLeave}
            >
                <LinkedInIcon />
            </a>
            <a
                style={{
                    ...styles.icon,
                    color: hoveredIcon === 'codepen' ? color.colors.title : color.colors.secondaryText
                }}
                href="https://codepen.io/leonardobarrera"
                target="blank"
                onMouseEnter={() => handleMouseEnter('codepen')}
                onMouseLeave={handleMouseLeave}
            >
                <i className="fab fa-codepen"></i>
            </a>
        </Stack>
    );
};
