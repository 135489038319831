import React from 'react'
import { Container, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import color from '../themes/colors'
import { Experience } from './Experience';
import { ProjectList } from './ProjectList';
import { Footer } from './Footer';

export const Main = () => {
    const styles = {
        contaienr: {
            marginLeft: '45%',
            padding: '96px 20px',
            boxSizing: 'border-box',
            color: color.colors.secondaryText,
            zIndex: '999'
        },
        about: {
            height: '450px',
            textAlign: 'start',
            padding: '2rem'
        },
        subtitle: {
            fontSize: '20px',
            lineHeight: '28px',
            textAlign: 'start',
            letterSpacing: '-0.5px',
            color: color.colors.title,
            margin: '16px 0'
        },
    };
    return (
        <Container className='main' sx={styles.contaienr}>

            <Box sx={styles.about} id="acercade">
                <Box className="titleSection">
                    <Typography sx={styles.subtitle} variant="subtitle1">Acerca De</Typography>
                </Box>
                <Typography variant="body1">
                    Hola, soy Leonardo. Como desarrollador, mi trayectoria incluye experiencia con Java, Spring Boot, ReactJS y SQL. He tenido la oportunidad de familiarizarme con herramientas valiosas como GitLab, pipelines, AWS y Docker en el desarrollo de proyectos sólidos y funcionales.
                    En mi experiencia freelance full-stack, he colaborado con diversas empresas aportando soluciones tecnológicas efectivas y personalizadas. Mi conocimiento práctico en la gestión de pipelines y el uso moderado de tecnologías CI/CD, complementan mi habilidad para entregar proyectos de manera oportuna y eficiente.
                    Mi compromiso es continuar aprendiendo y adaptándome a las nuevas tendencias tecnológicas para ofrecer soluciones relevantes y actuales. Si buscas un profesional equilibrado, consciente y proactivo, estaré encantado de conversar contigo sobre cómo puedo aportar a tu proyecto o equipo.
                </Typography>
            </Box>
            <Box id="experiencia">
                <Box className="titleSection">
                    <Typography className='experienciaTitle' style={{paddingLeft:'32px'}} sx={styles.subtitle} variant="subtitle1">Experiencia</Typography>
                </Box>
                <Experience></Experience>
            </Box>
            <Box id="proyectos">
            <Box className="titleSection">
                    <Typography className='proyectTitle' style={{margin:'32px 16px 0'}} sx={styles.subtitle} variant="subtitle1">Proyectos</Typography>
                </Box>
                <ProjectList />
            </Box>
            <Footer />


        </Container>
    )
}
