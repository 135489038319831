import React from 'react'
import color from '../themes/colors';

export const Footer = () => {

    const styles = {
        text: {
            lineHeight: '24px',
            textAlign: 'start',
            color:color.colors.secondaryText,
            margin: '16px'
        },  
    }

  return (
    <div className='footer' style={styles.text}>Codificado en <a className='link' href="https://code.visualstudio.com/" target='_blank'>Visual Studio Code</a>. Construido con <a className='link' href="https://es.react.dev/" target='_blank'>React.js</a> y <a className='link' href="https://mui.com/" target='_blank'>Material UI</a>. Todo el texto está escrito en el tipo de letra <a className='link' href="https://fonts.google.com/specimen/Roboto" target='_blank'>Roboto</a>.</div>
  )
}
